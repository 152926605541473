import React, { useState } from 'react'
import { StaticQuery } from "gatsby"
import { graphql } from "gatsby"
import useTranslations from '../useTranslations';
import Languages from '../Languages';
import Logo from '../Logo';
import LocalizedLink from '../LocalizedLink'
import * as S from './styled';
import {
  ButtonGroup,
  Button,
  ButtonOutline
} from '../LandingPage/styled'
import { LocaleContext } from '../Layout';

export default () => {
  return (
    <StaticQuery
      query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
      render={data => {

        const { navbar, request, landingPage, footerContent } = useTranslations()
        const { locale } = React.useContext(LocaleContext);

        const [close, setClose] = useState(true)
        function closeNotification() {
          if (!close) setClose(true)
          else setClose(false)
        }

        const [open, setOpen] = useState(true)
        function openMenu() {
          if (!open) setOpen(true)
          else setOpen(false)
        }

        const menu = [
          {
            title: navbar.solutions,
            link: navbar.solutionsurl
          },
          {
            title: navbar.products,
            link: navbar.productsURL
          },
          {
            title: navbar.ref,
            link: navbar.refURL,
          },
          {
            title: 'Blog',
            link: 'blog'
          },
          // {
          //   title: 'E-Kaynaklar',
          //   link: '/dokumanlar'
          // },
          {
            title: navbar.contact,
            link: navbar.contactURL
          },
          {
            title: navbar.help,
            link: navbar.helpURL,
          },
        ]

        return (<>

          {/* {locale == 'tr' ?
            <S.Container style={{ display: `${!close ? 'none' : null}` }}>

              <a href="/asi-dolaplari-takip-sistemi" className="header-notify-link">
                🚀
                  <span className="header-notify-title">
                  Aşı Dolapları İçin Takip Sistemi Avantajlı Teklifi
                  <span className='header-span'> Son Gün: 1 Mart 2021</span>  &#x2192;
                </span>
              </a>
              <div className="close-button" onClick={closeNotification} title='Close the notification' />

            </S.Container>
            : null
          } */}
          <S.Header>
            <S.Container>
              <S.HeaderWrapper >
                <S.NavLink to='/'>
                  <Logo />
                </S.NavLink>
                <S.NavSection aria-label='Main Nav'
                  className={`main-nav ${!open ? 'open' : ''}`} 
                  >
                  <S.MenuButton
                    onClick={openMenu}
                    aria-label="Menu">
                    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z" /></svg>
                  </S.MenuButton>
                  <S.NavMenu
                    className={`${!open ? 'open-menu' : ''}`}>
                    <S.NavMenuScroller>
                      <S.NavMenuInner>
                        <S.CloseButton
                          onClick={openMenu}
                          aria-label="Close" >
                          <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z" /></svg>
                        </S.CloseButton>
                        <S.NavItemsWrapper>
                          
                          {menu.map(data => (
                            <S.NavItems>
                              <S.NavLink
                                to={`/${data.link}/`}
                                title={data.title}
                                
                                >
                                {data.title}
                              </S.NavLink>
                            </S.NavItems>
                          ))}
                          <ButtonGroup
                            className={`mobile-buttons ${!open ? 'open' : ''}`} >
                            <LocalizedLink to={`/${request}`}>
                              <Button
                        
                              >
                                {landingPage.freeDemo}
                              </Button>
                            </LocalizedLink>
                            <LocalizedLink to={`/${navbar.solutionsurl}`}>
                              <ButtonOutline
                           >
                                {landingPage.getProducts}
                              </ButtonOutline>
                            </LocalizedLink>
                          </ButtonGroup>
                          <Languages />
                        </S.NavItemsWrapper>
                      </S.NavMenuInner>
                    </S.NavMenuScroller>
                  </S.NavMenu>
                </S.NavSection>
              </S.HeaderWrapper>
            </S.Container>
          </S.Header>
        </>
        )
      }}
    />
  )
}