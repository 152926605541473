import styled from 'styled-components'
import React from 'react'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import LocalizedLink from '../LocalizedLink'


export const Container = styled.div`
width: 100%;
margin: auto;
max-width: 1140px;
max-width: var(--width-container);
padding: 1.2rem;

background: #f3f3f3;
padding: 1rem;
border-radius: 0.7rem;
margin-top: 1rem;
`;

export const Header = styled.header`
`;

export const MenuButton = styled.button`
${media.greaterThan('medium')`
display:none
}`}
align-items: center;
display: inline-flex;
font-size: 1.5em;
height: 1.25em;
justify-content: center;
line-height: 1.25em;
padding: 0;
position: relative;
width: 1.25em;
${media.lessThan('medium')`
margin-left: auto;
background: 0;
border: 0;
cursor:pointer;
color: var(--secondary);
}`}
`;

export const CloseButton = styled.button`
${media.greaterThan('medium')`
display:none
}`}
align-items: center;
display: inline-flex;
font-size: 1.5em;
height: 1.25em;
justify-content: center;
line-height: 1.25em;
padding: 0;
position: relative;
width: 1.25em;
${media.lessThan('medium')`
margin-left: auto;
background: 0;
border: 0;
cursor:pointer;
color: var(--secondary);
}`}
`;



export const NavSection = styled.nav`
display: flex;
gap: 1.4rem;

${media.lessThan('medium')`

}`}
`;

export const NavMenu = styled.div`
${media.lessThan('medium')`
    backface-visibility: hidden;
    background-color: #fff;
    bottom: 0;
    right: -100%;
    max-width: 360px;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    transition: right .3s cubic-bezier(.65,.05,.36,1),visibility 0s .3s; 
    visibility: hidden;
    width: 100%;
    z-index: 999;
  }`}
`;

export const NavMenuScroller = styled.div`

${media.lessThan('medium')`
height: 100%;
overflow-y: auto;
position: relative;
}`}
`;
export const NavMenuInner = styled.div`
${media.lessThan('medium')`
padding: 1.125rem 1.5rem;
display: grid;
gap: 1.3rem;
}`}
`;

export const NavItemsWrapper = styled.ul`
display: flex;
gap: 2rem;
font-weight: var(--bold);
${media.lessThan('medium')`
display:grid;
gap: 0;
}`}
`;


export const NavItems = styled.li`
font-size: var(--textInfo);
color: var(--secondary);
font-weight: var(--bold);

${media.lessThan('medium')`
border-top: 1px solid #efefef;
}`}
`;

export const HeaderWrapper = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
`;


export const NavLink = styled(LocalizedLink)`
${media.lessThan('medium')`
display: block;
padding: .75rem 0;
}`}
&:hover{
 color: var(--secondaryHover);
  } 
`;

