import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'gatsby';

export const LanguageSelectWrapper = styled.div`
    margin: auto;
    width: auto;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
   
    ${media.lessThan('medium')`
    margin: auto;
    margin-right: 0;
    `}
    ${media.lessThan('large')`
    margin: auto;
    margin-right: 0;
    `}
    ${media.lessThan('small')`
    margin: auto;
    margin-right: 0;
    `}
`;
export const LanguageLink = styled(Link)`
  display: inline-block;
  font-weight: var(--light);
  color: var(--secondary);
  font-size: var(--textUltraSmall);
 &:hover {
  color: var(--secondaryHover);
 }
`;

