// Only one item MUST have the "default: true" key

module.exports = {
  tr: {
    default: true,
    path: `tr`,
    locale: `tr-TR`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `tr`,
    ogLanguage: `tr_TR`,
    defaultTitle: `Retmon | Ortam İzleme Sistemleri`,
    defaultDescription: `Retmon Ortam İzleme, Veri Toplama ve Analiz, Isı ve Nem Takip Sistemleri`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Retmon | Environment Monitoring Systems`,
    defaultDescription: `Retmon | Environment Monitoring, Data Collection and Temperature and Humidity Monitoring Solutions`,
  },

}
