import styled from 'styled-components';
import media from 'styled-media-query';

export const Logo = styled.img`
width:11rem;
${media.lessThan('medium')`
width:8rem
  `}

`
