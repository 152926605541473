import React from 'react'
import useTranslations from '../useTranslations'
import * as S from './styled'
import { LocaleContext } from '../Layout'
// import { FaLinkedin, FaTwitter, FaFilePdf } from 'react-icons/fa'

const Footer = () => {
  const {
    retmonTitle,
    footercopyrights,
    updated,
    footerAllrights,
    landingPage,
    navbar,
  } = useTranslations()

  const { locale } = React.useContext(LocaleContext)

  const KonzekLogoURL = 'https://res.cloudinary.com/konzek/image/upload/v1602783704/Konzek/logo/Konzek.svg'
  const YerliUretimLogo = 'https://res.cloudinary.com/konzek/image/upload/v1610693723/Retmon/web/yerli-uretim.svg'
  const RetmonDefaultIcon = 'https://res.cloudinary.com/konzek/image/upload/v1602253144/Retmon/web/retmon-icon-default.jpg'
  const RetmonLogo = 'https://res.cloudinary.com/konzek/image/upload/v1605536864/Retmon/Logo/retmon_logo.svg'

  const menu = [
    {
      column: {
        title: navbar.solutions,
        link: navbar.solutionsurl,
        list: [
          {
            title: navbar.solutionsSub.ems,
            link: 'ems',
          },
          {
            title: navbar.solutionsSub.bms,
            link: 'bms',
          },
          {
            title: navbar.solutionsSub.dcim,
            link: 'dcim',
          },
          {
            title: navbar.solutionsSub.pms,
            link: 'pms',
          },
          {
            title: navbar.solutionsSub.iot,
            link: 'iot',
            new: true
          },
        ]
      },
    },
    {
      column: {
        title: navbar.products,
        link: navbar.productsURL,
        list: [
          {
            title: navbar.productsSub.dataCollection,
            link: navbar.productsSub.dataCollectionURL,
          },
          {
            title: navbar.productsSub.sensorsEquipments,
            link: navbar.productsSub.sensorsEquipmentsURL,
          }
        ]
      },
    },
    {
      column: {
        title: landingPage.documents,
        docs: false,
        link: "#",
        list: [
          {
            title: 'Retmon 2020',
            link: 'docs/retmon-katalog-2020.pdf',
          },
          {
            title: 'Retmon EMS',
            link: 'docs/Retmon_EMS.pdf',
          },
          {
            title: 'Retmon DCIM',
            link: 'docs/retmon-dcim-TR.pdf',
          },
        ]
      },
    },
    {
      column: {
        title: 'Retmon Hakkında',
        link: "#",
        list: [
          {
            title: navbar.faq,
            link: navbar.faqURL,
          },
          {
            title: 'Blog',
            link: 'blog',
          },
          {
            title: navbar.helpUR,
            link: navbar.help,
          },
          {
            title: navbar.contact,
            link: navbar.contactURL,
          },
        ]
      },
    },
  ]


  return (
    <>
      <S.FooterWrapper>
        <S.Container>
          <S.Icon
            width='8rem'
            src={RetmonLogo}
            alt={retmonTitle}
          />
          <S.FooterGridWrapper>
            {menu.map(data => (
              <S.Col>
                <S.FooterTitle>
                  <S.FooterLink
                    to={`/${data.column.link}/`}>
                    {data.column.title}
                  </S.FooterLink>
                </S.FooterTitle>
                <ul>
                  {data.column.list.map(data => (
                    <li>
                      { data.docs ?
                        <a
                          href={`/${data.link}`}
                          title={data.title}>
                          {data.title}
                        </a>
                        :
                        <S.FooterLink
                          target="_blank"
                          rel="noopener"
                          key={data.id}
                          to={`/${data.link}`}>
                          {data.title} {data.new ? <S.Badge>{updated}</S.Badge> : null}
                        </S.FooterLink>
                      }
                    </li>
                  ))}
                </ul>
              </S.Col>
            ))}

          </S.FooterGridWrapper>
          <S.FooterFlex>
            <S.Icon
              src={YerliUretimLogo}
              alt="Retmon Yerli Üretim"
            />
          </S.FooterFlex>
          <S.FooterContainer>
            <S.FooterFlex>
              <S.infoText>
                © {retmonTitle} <br />
                {footercopyrights}
                <br /> {footerAllrights} | {new Date().getFullYear()}
              </S.infoText>
              <a
                href='https://www.konzek.com'
                title="Konzek Teknoloji">
                <S.Icon
                  src={KonzekLogoURL}
                  alt="Konzek Teknoloji"
                />
              </a>
            </S.FooterFlex>
          </S.FooterContainer>
        </S.Container>
      </S.FooterWrapper>
    </>
  )
}

export default Footer

