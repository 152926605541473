import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink'

export const FooterWrapper = styled.footer`
padding: 1rem;
background: var(--grayBackground);
color: var(--text);
padding-bottom: 1rem;
`;

export const Container = styled.div`
width: 100%;
margin: auto;
max-width: var(--width-container);
`;

export const SocialWrapper = styled.div`
svg{
  width:2rem;
  height: 2rem;
  margin: 2rem;
  transition: fill .1s ease-in;
}
svg:hover{
 fill:var(--red);
 cursor:pointer;
 transition: fill .1s ease-in;
}
`
export const FooterContainer = styled.div`
  line-height: 1.25;
  color: var(--text);
  width: 100%;
  text-align:-webkit-center;
  max-width: var(--width-container);
  margin: 0 auto;
  margin-top:${props => props.flat ? "0rem" : "2rem"}; 
  ul li{
 margin-bottom:0;
  }
  ${media.lessThan('small')`
  margin-bottom: 2rem;
  position: unset;
  `}

`;
export const Col = styled.div`
 display: grid;
color: var(--text);
  text-align:-webkit-left;  
  text-align: left;
  p {
    margin: 1rem 0;
  }
  li{
    margin-bottom: 0.3rem;
  }
  ul li svg {
    margin-right:3px
  }
  ${media.lessThan('small')`
  margin-bottom: 5rem;
  position: unset;
  `}
h3{
  color: var(--red);
  text-transform: uppercase;
}
`;
export const FooterGridWrapper = styled.div`
display: grid;
gap:  1rem;
grid-template-columns: repeat(4, minmax(0, 1fr));
margin-top:${props => props.flat ? "0rem" : "5rem"}; 
justify-content: center;
flex-wrap:wrap;
align-items: baseline;
${media.lessThan('medium')`
grid-template-columns: repeat(2, minmax(0, 1fr));
`}

${media.lessThan('small')`
grid-template-columns: repeat(2, minmax(0, 1fr));
`}
`;

export const FooterLink = styled(LocalizedLink)`
font-size: var(--textBase);
`;

export const FooterTitle = styled.text`
font-size: var(--textTitle);
font-weight: var(--bold);
margin-bottom: 1rem;
`;

export const FooterEmptyLink = styled.a`
`;

export const infoText = styled.p`
 color: var(--info);
 text-align:left;
  font-weight: 100;
  font-size: var(--textInfo);
`;

export const Badge = styled.span`
 color: var(--red);
  font-weight: 100;
  font-size: var(--textInfo);
`;

export const Icon = styled.img`
width: ${props => props.width || '5rem'};
 margin-left: 0;
margin-top: 2rem;
`;

export const FooterFlex = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;
