import React from 'react';
// import LocalizedLink from '../LocalizedLink';
import * as S from './styled';
import { LocaleContext } from '../Layout';

const Languages = (to) => {

  const { locale } = React.useContext(LocaleContext);

  return (
    <S.LanguageSelectWrapper>
      { locale === 'tr' ?
        <S.LanguageLink to={`/en/`} hrefLang="en"> Switch to English</S.LanguageLink> :
        <S.LanguageLink to="/" hrefLang="tr">Switch to Turkish</S.LanguageLink>
      }
    </S.LanguageSelectWrapper>
  );
}

export default Languages;
