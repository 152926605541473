import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LocaleContext } from './Layout'

function useTranslations() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations

  const { rawDataEN, rawDataTR } = useStaticQuery(query)

  const rawedData = locale === 'tr' ? rawDataTR : rawDataEN
  // Simplify the response from GraphQL
  const simplified = rawedData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0]

  if (locale === 'tr') return translations.tr
  if (locale === 'en') return translations.en
}

export default useTranslations

const query = graphql`
  query useTranslations {
    rawDataTR: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            id
            tr {
              landingPage {
                getProducts
                freeDemo
                downloadsectionTitle
                download
                title_1
                title_2
                subtitle_1
                start
                findYour
                findYour_2
                commercialSecond_Title
                commercialSecond_Desc
                commercialLast_Title
                commercialLast_Desc
                watchWarn
                manageAllData
                manageAllDataDesc
                videoCommercialTitle
                videoCommercialSubTitle
                videoCommercialDesc
                solutionsDesc
                ourReferances
                seeReferances
                allBlogs
                more
                toDate
                sensor
                controllers
                produced
                askPriceTitle
                askPriceDescription
                offerButton
                aboutTitle
                aboutDesc
                aboutDesc2
                contactTitle
                contactEmail
                contactPhone
                contactAddress
                contactAddressDetail
                followUs
                document
                documents
                support
              }
              formlang {
                choose_product
                choose_service
                service_detail
                service_detail_placeholder
                companyAdmin
                campaignCode
                companyInfos
                requiredField
                name
                mail
                phone
                company
                location
                technicalInfos
                ethernet
                total_tag_number
                alarm_notifications
                alarm_notifications_sms
                alarm_notifications_email
                alarm_notifications_siren_sound
                alarm_notifications_siren_sound_light
                alarm_notifications_phone_call
                alarm_notifications_phone_notify
                threeg_modem
                threeg_mode_info
                select_sensors
                temperature
                humidity
                motion
                water_leak
                smoke
                combo
                air
                air_flow
                impact
                light
                door_sensor
                energy
                ip_cam
                energy_parameter
                energy_parameter_place
                sensor_distance
                sensor_distance_place
                number_of_regions
                room_distance
                room_distance_place
                surface_area
                mini_UPS
                installation
                installation_info
                installation_server
                server_preference
                server_preference_dcim
                server_preference_local
                keep_data
                keep_data_info
                keep_data_one_month
                keep_data_two_years
                mention
                send
                yes
                no
                extra
                sending
                selectFile
                labelFile
                infoFile
              }
              solutionsPage {
                title
              }
              navbar {
                contact
                contactURL
                corporate
                home
                solutions
                solutionsurl
                blog
                faq
                faqURL
                help
                helpURL
                ref
                refURL
                products
                productsURL
                productsSub {
                  dataCollection
                  dataCollectionURL
                  sensorsEquipmentsURL
                  sensorsEquipments
                }
                solutionsSub {
                  bms
                  dcim
                  ems
                  pms
                  iot
                }
              }
              footercopyrights
              footerAllrights
              retmonTitle
              retmonDescription
              request
              updated
              lang
              back
              searchPlaceHolder
              toRead
              toReadMin
              modules {
                modulesTitle
                modulesDesc
                multi
                heatMap
                heatMapT
                humid
                cam
                location
                graphMon
                alarmMan
                dataCollect
                reportMan
                historicalData
                webApp
                userMan
                ruleEditor
                visual
                ruleMan
                assetMan
                changeMan
                energyMan
                consMan
                mainMan
                taskMan
                cableMan
                available
                unavailable
                optional
              }
              seoDescriptions {
                terminalsList
              }
            }
          }
        }
      }
    }
    rawDataEN: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            id
            en {
              landingPage {
                getProducts
                freeDemo
                downloadsectionTitle
                download
                title_1
                title_2
                subtitle_1
                start
                findYour
                findYour_2
                commercialSecond_Title
                commercialSecond_Desc
                commercialLast_Title
                commercialLast_Desc
                watchWarn
                manageAllData
                manageAllDataDesc
                videoCommercialTitle
                videoCommercialSubTitle
                videoCommercialDesc
                solutionsDesc
                ourReferances
                seeReferances
                allBlogs
                more
                toDate
                sensor
                controllers
                produced
                askPriceTitle
                askPriceDescription
                offerButton
                aboutTitle
                aboutDesc
                aboutDesc2
                contactTitle
                contactEmail
                contactPhone
                contactAddress
                contactAddressDetail
                followUs
                document
                documents
                support
              }
              formlang {
                choose_product
                choose_service
                service_detail
                service_detail_placeholder
                companyAdmin
                campaignCode
                companyInfos
                requiredField
                name
                mail
                phone
                company
                location
                technicalInfos
                ethernet
                total_tag_number
                alarm_notifications
                alarm_notifications_sms
                alarm_notifications_email
                alarm_notifications_siren_sound
                alarm_notifications_siren_sound_light
                alarm_notifications_phone_call
                alarm_notifications_phone_notify
                threeg_modem
                threeg_mode_info
                select_sensors
                temperature
                humidity
                motion
                water_leak
                smoke
                combo
                air
                air_flow
                impact
                light
                door_sensor
                energy
                ip_cam
                energy_parameter
                energy_parameter_place
                sensor_distance
                sensor_distance_place
                number_of_regions
                room_distance
                room_distance_place
                surface_area
                mini_UPS
                installation
                installation_info
                installation_server
                server_preference
                server_preference_dcim
                server_preference_local
                keep_data
                keep_data_info
                keep_data_one_month
                keep_data_two_years
                mention
                send
                yes
                no
                extra
                sending
                selectFile
                labelFile
                infoFile
              }
              solutionsPage {
                title
              }
              navbar {
                contact
                contactURL
                corporate
                home
                solutions
                solutionsurl
                blog
                faq
                faqURL
                help
                helpURL
                ref
                refURL
                products
                productsURL
                productsSub {
                  dataCollection
                  dataCollectionURL
                  sensorsEquipmentsURL
                  sensorsEquipments
                }
                solutionsSub {
                  bms
                  dcim
                  ems
                  pms
                  iot
                }
              }
              footercopyrights
              footerAllrights
              retmonTitle
              retmonDescription
              request
              updated
              lang
              back
              searchPlaceHolder
              toRead
              toReadMin
              modules {
                modulesTitle
                modulesDesc
                multi
                heatMap
                heatMapT
                humid
                cam
                location
                graphMon
                alarmMan
                dataCollect
                reportMan
                historicalData
                webApp
                userMan
                ruleEditor
                visual
                ruleMan
                assetMan
                changeMan
                energyMan
                consMan
                mainMan
                taskMan
                cableMan
                available
                unavailable
                optional
              }
              seoDescriptions {
                terminalsList
              }
            }
          }
        }
      }
    }
  }
`
