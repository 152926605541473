// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-campaign-js": () => import("./../../../src/templates/campaign.js" /* webpackChunkName: "component---src-templates-campaign-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-data-collection-terminals-js": () => import("./../../../src/templates/DataCollectionTerminals.js" /* webpackChunkName: "component---src-templates-data-collection-terminals-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-form-js": () => import("./../../../src/templates/form.js" /* webpackChunkName: "component---src-templates-form-js" */),
  "component---src-templates-help-js": () => import("./../../../src/templates/help.js" /* webpackChunkName: "component---src-templates-help-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-products-list-js": () => import("./../../../src/templates/products-list.js" /* webpackChunkName: "component---src-templates-products-list-js" */),
  "component---src-templates-referances-js": () => import("./../../../src/templates/referances.js" /* webpackChunkName: "component---src-templates-referances-js" */),
  "component---src-templates-sensors-equipments-js": () => import("./../../../src/templates/SensorsEquipments.js" /* webpackChunkName: "component---src-templates-sensors-equipments-js" */),
  "component---src-templates-sensors-list-js": () => import("./../../../src/templates/sensors-list.js" /* webpackChunkName: "component---src-templates-sensors-list-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-solutions-list-js": () => import("./../../../src/templates/solutions-list.js" /* webpackChunkName: "component---src-templates-solutions-list-js" */),
  "component---src-templates-terminals-list-js": () => import("./../../../src/templates/terminals-list.js" /* webpackChunkName: "component---src-templates-terminals-list-js" */)
}

